/**
 * Example for running an A/B test
 * This exposes the function `runABTest2()` to the window, so it can be activated
 * by Optimize. It hides/shows all `<div>`s in the page with `data-ab2-hide` and
 * `data-ab2-show` attributes.
 */

const runABTest10 = () => {
  [...document.querySelectorAll('[data-ab10-hide]')].forEach(($el) => {
    $el.hidden = true;
  });

  [...document.querySelectorAll('[data-ab10-show]')].forEach(($el) => {
    $el.hidden = false;
  });
};
 
const runABTest15 = () => {
  [...document.querySelectorAll('[data-ab15-hide]')].forEach(($el) => {
    $el.hidden = true;
  });

  [...document.querySelectorAll('[data-ab15-show]')].forEach(($el) => {
    $el.hidden = false;
  });
};


const runABTest24 = () => {
  [...document.querySelectorAll('[data-ab24-hide]')].forEach(($el) => {
    $el.hidden = true;
  });

  [...document.querySelectorAll('[data-ab24-show]')].forEach(($el) => {
    $el.hidden = false;
  });
};

const runABTest25 = () => {
  [...document.querySelectorAll('[data-ab25-hide]')].forEach(($el) => {
    $el.hidden = true;
  });

  [...document.querySelectorAll('[data-ab25-show]')].forEach(($el) => {
    $el.hidden = false;
  });
};

// make function callable from Optimize
window.runABTest10 = runABTest10;
window.runABTest15 = runABTest15;
window.runABTest24 = runABTest24;
window.runABTest25 = runABTest25;

// enable to test locally
//window.runABTest10();
//window.runABTest15();


// This fires a datalayer event, so A/B tests are activated after the async main script executes.
// window.dataLayer.push({ event: 'optimize.activate' });
