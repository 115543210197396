const testImg = typeof Image === 'undefined' ? { style: { 'object-position': 1 } } : new Image();
const supportsObjectFit = 'object-fit' in testImg.style;

if (!supportsObjectFit) {
  import('object-fit-images')
    .then((ofi) => {
      window.objectFitImages = ofi.default;
      window.objectFitImages();
    });
}
