export const setStorageItem = (name, value) => {
  window.localStorage.setItem(name, JSON.stringify(value));
};

export const getStorageItem = (name) => {
  let favorites = [];

  if (window.localStorage.getItem(name)) {
    try {
      favorites = JSON.parse(
        localStorage.getItem(name),
      );
    } catch (e) {
      //
    }
  }

  return favorites;
};
