import { getStorageItem, setStorageItem } from '../utilities/storage';

class ToggleFavorite extends HTMLElement {
  constructor() {
    super();
    this.favorites = [];
    this.toggle = this.toggle.bind(this);

    this.listenForUpdate = this.listenForUpdate.bind(this);
  }

  get productCode() {
    return this.getAttribute('productcode');
  }

  static get observedAttributes() {
    return ['productcode'];
  }

  connectedCallback() {
    this.$btn = this.querySelector('button');
    this.$show = this.$btn.querySelector('[data-add]');
    this.$hidden = this.$btn.querySelector('[data-remove]');
    this.favorites = getStorageItem('favorites');
    this.updateButton();
    this.$btn.addEventListener('click', this.toggle);
    window.addEventListener('updateFavorites', this.listenForUpdate);
  }

  disconnectedCallback() {
    this.$btn.removeEventListener('click', this.toggle);
    window.removeEventListener('updateFavorites', this.listenForUpdate);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    // Vue does not really disconnect / reconnect, so updating it manually
    if (name === 'productcode' && oldValue && oldValue !== newValue) {
      this.updateButton();
    }
  }


  listenForUpdate() {
    this.favorites = getStorageItem('favorites');
    this.updateButton();
  }

  updateButton() {
    if (this.favorites.some((fav) => fav.Id === this.productCode)) {
      this.$show.hidden = true;
      this.$hidden.hidden = false;
    } else {
      this.$show.hidden = false;
      this.$hidden.hidden = true;
    }
  }

  toggle() {
    if (!this.isInFavorites()) {
      this.addToStorage();
    } else {
      this.removeFromStorage();
    }

    this.fireEvent();
  }

  isInFavorites() {
    this.favorites = getStorageItem('favorites');
    return this.favorites.some((fav) => fav.Id === this.productCode);
  }

  addToStorage() {
    this.favorites.push({
      Id: this.productCode,
      EventCode: null,
    });

    setStorageItem('favorites', this.favorites);
  }

  removeFromStorage() {
    this.favorites = this.favorites.filter((fav) => fav.Id !== this.productCode);
    setStorageItem('favorites', this.favorites);
  }

  fireEvent() {
    const event = new CustomEvent('updateFavorites');
    window.dispatchEvent(event);
    return this;
  }
}

customElements.define('toggle-favorite', ToggleFavorite);
