/**
 This sets a message to the browser tab if it loses focus.
 It uses the Page Visibility API to set a new text to the <title> element.
 */

class TabNotification {
  constructor() {
    this.$body = document.querySelector('body');
    this.timeout = this.$body.dataset.tabNotificationTimeout;
    this.message = this.$body.dataset.tabNotification;
    this.originalTitle = document.title;
  }

  init() {
    // if properties are not set
    if (!this.timeout) {
      return;
    }


    document.addEventListener('visibilitychange', () => {
      // if tab is not in view
      if (document.hidden) {
        this.startTimeout();
      } else {
        // clear timeout, restore original title
        clearTimeout(this.setTimeout);
        document.title = this.originalTitle;
      }
    });
  }

  startTimeout() {
    // prefix title with message after timeout
    this.setTimeout = setTimeout(() => {
      document.title = `${this.message} - ${this.originalTitle}`;
    }, parseFloat(this.timeout));
  }
}

new TabNotification().init();
