/**
 * Adds GTM event handlers to all forms
 */

const pushToDatalayer = (event) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

class GtmForm {
  constructor($el) {
    this.$el = $el;
    this.$inputs = $el.querySelectorAll('input, select');
    this.gtmForm = $el.dataset.gtmForm; // form name
    this.handleInputFirstFocus = this.handleInputFirstFocus.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
  }

  init() {
    this.addInputEventListeners();
    this.addSubmitEventListener();
  }

  addInputEventListeners() {
    Array.from(this.$inputs).forEach(($input) => {
      $input.addEventListener('focus', this.handleInputFirstFocus);
      $input.addEventListener('focus', this.handleInputFocus);
    });
  }

  addSubmitEventListener() {
    /*
      If the form has custom validation. Use the validatedSubmit event.
      Otherwise, use the normal submit event.
    */

    if (this.$el.dataset.module || this.$el.dataset.module === 'validate') {
      this.$el.addEventListener('validatedSubmit', () => {
        let data = null;
        if (this.$el.getAttribute('method').toLowerCase() === 'get') {
          data = {};
          const formData = new FormData(this.$el);

          [...formData.entries()].forEach(([key, value]) => {
            data[key] = value;
          });
        }
        // UA-based event
        pushToDatalayer({
          event: 'Goal',
          category: 'Form',
          action: `Form - ${this.gtmForm || 'Unnamed form'}`,
          label: 'Form finish',
          data,
        });
        // GA4/UA-based event
        pushToDatalayer({
          event: 'form_submission',
          element: this.gtmForm,
          status: 'finish',
          data,
        });
      });
    } else {
      this.$el.addEventListener('submit', () => {
        // duplicate code, could be nicer
        let data = null;
        if (this.$el.getAttribute('method').toLowerCase() === 'get') {
          data = {};
          const formData = new FormData(this.$el);

          [...formData.entries()].forEach(([key, value]) => {
            data[key] = value;
          });
        }
        // UA-based event
        pushToDatalayer({
          event: 'Goal',
          category: 'Form',
          action: `Form - ${this.gtmForm || 'Unnamed form'}`,
          label: 'Form finish',
          data,
        });
        // GA4/UA-based event
        pushToDatalayer({
          event: 'form_submission',
          element: this.gtmForm,
          status: 'finish',
          data,
        });
      });
    }
  }

  handleInputFirstFocus() {
    // GA event
    pushToDatalayer({
      event: 'Goal',
      category: 'Form',
      action: `Form - ${this.gtmForm || 'Unnamed form'}`,
      label: 'Form start',
    });
    // UA/GA4 event
    pushToDatalayer({
      event: 'form_submission',
      element: this.gtmForm,
      status: 'start',
    });

    // Remove event listener after first use.
    Array.from(this.$inputs).forEach(($input) => {
      $input.removeEventListener('focus', this.handleInputFirstFocus);
    });
  }

  handleInputFocus(e) {
    const label = this.$el.querySelector(`label[for="${e.target.id}"]`);

    pushToDatalayer({
      event: 'Goal',
      category: 'Form',
      action: `Form - ${this.gtmForm || 'Unnamed form'}`,
      label: `Focus field - ${label.innerText || 'unlabeled field'}`,
    });
  }
}

const addGtmListeners = {
  init: (context = document) => {
    // get all forms on page
    const $forms = context.querySelectorAll('form');
    addGtmListeners.initAll($forms);
  },

  initAll: ($forms) => {
    if ($forms) {
      [...$forms].forEach(($form) => {
        // set data-no-gtm to a form you don't want to add listeners to
        if ($form.dataset.noGtm === undefined) {
          // create a class instance for each form
          const gtmForm = new GtmForm($form);
          gtmForm.init();
        }
      });
    }
  },
};

addGtmListeners.init();

export default addGtmListeners;
